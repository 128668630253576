.hero-container {
  padding-left: 1px; }
  .hero-container.cls {
    min-height: 181px; }
    @media only screen and (min-width: 768px) {
      .hero-container.cls {
        min-height: 525px; } }
  .hero-container .carousel-indicators {
    bottom: 0px; }
  .hero-container .carousel-control .left {
    background: none;
    background: none; }
  .hero-container .carousel-control .right {
    background: none; }
    .hero-container .carousel-control .right .right {
      background: none; }

.homepage-tiles-container h3.row-heading {
  font-family: "PT Serif", serif;
  font-size: 18px;
  text-align: center; }

.homepage-tiles-container .linkedimageblock,
.homepage-tiles-container .captionedimageblock {
  padding-bottom: 10px;
  padding-top: 10px; }

@media only screen and (min-width: 768px) {
  .homepage-tiles-container h3.row-heading {
    font-size: 24px; }
  .homepage-tiles-container [class^="col-"],
  .homepage-tiles-container [class*="col-"] {
    padding-bottom: 10px;
    padding-top: 10px; } }
