﻿.hero-container {
    padding-left: 1px;

    &.cls {
        min-height: 181px;
        // tablets and desktops
        @media only screen and (min-width: $screen-sm-min) {
            min-height: 525px;
        }
    }

    .carousel-indicators {
        bottom: 0px;
    }

    .carousel-control {
        .left {
            background: none;
            background: none;
        }

        .right {
            background: none;

            .right {
                background: none;
            }
        }
    }
}
