﻿

// mobile first styles

.homepage-tiles-container {
    h3.row-heading {
        font: {
            family: $pt-serif;
            size: $font-size-large;
        }

        text: {
            align: center;
        }
    }

    .linkedimageblock,
    .captionedimageblock {
        padding: {
            bottom: 10px;
            top: 10px;
        }
    }
}

// tablets and desktops
@media only screen and (min-width: $screen-sm) {

    .homepage-tiles-container {

        h3.row-heading {
            font: {
                size: 24px;
            }
        }

        [class^="col-"],
        [class*="col-"] {
            padding: {
                bottom: 10px;
                top: 10px;
            }
        }
    }
}
